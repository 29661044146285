import Link from 'next/link'
import { Button } from '../lib/components';

export default function NotFound() {
  return (
    <div className="mx-5 lg:mx-20 xl:mx-40 py-2 space-y-16 flex-1">
      <section className="mx-4">
        <h1 className="text-3xl font-bold uppercase mt-10 mb-16">Page non trouvée</h1>
        <div className="flex justify-center">
          <div className="bg-base-light p-3 shadow-xl flex flex-col">
            <h2 className="mb-5 font-bold text-xl">La page demandée est introuvable...</h2>
            <Button
              link={true}
              action="/"
              text="Retour à l'accueil"
              className="mx-auto px-10 py-4 text-lg"
            />
          </div>
        </div>
      </section>
    </div>
  )
}